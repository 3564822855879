<template>
  <div class="waivers-page" data-v-step="15">
    <Title class="mb-40">Waivers</Title>
    <div data-v-step="17">
      <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
      <div v-else>
        <div class="waivers-page__wrapper">
          <Select
            v-model="selectedProductId"
            :options="productOptions"
            label="Product"
            placeholder="Select product"
            class="mb-32"
          />
          <Guard permission="waivers.create" v-slot="{ isAvailable }">
            <IconButton
              class="mb-16"
              v-if="isAvailable"
              @click="goToCreation"
              icon="plus"
              isRaised
            >
              Add New Waiver
            </IconButton>
          </Guard>
        </div>
        <div class="waivers-page__grid">
          <WaiverCard
            v-for="waiver in waivers"
            :key="waiver.id"
            :title="waiver.title"
            :is-active="waiver.id === activeWaiverId"
            :menu-options="menuOptions"
            @context-menu="handleContextMenu($event, waiver)"
            @select="selectActiveWaiver(waiver)"
          />
        </div>
      </div>
    </div>
    <div class="divider mt-40 mb-24" />
    <SignaturesSection :infinite-id.sync="infiniteId" />
  </div>
</template>

<script>
import WaiverCard from "@/components/waivers/WaiverCard";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import dialog from "@/plugins/dialog";
import SignaturesSection from "@/components/signatures/SignaturesSection";
import Guard from "@/components/common/Guard";

export default {
  name: "WaiversPage",
  components: { Guard, SignaturesSection, WaiverCard },
  data() {
    return {
      isLoading: false,
      signaturesLimit: 15,
      infiniteId: +new Date(),
    };
  },
  computed: {
    ...mapState({
      waivers: (state) => state.waivers.list,
      signatures: (state) => state.signatures.list,
      activeWaiverId: (state) => state.products.selectedProduct?.activeWaiverId,
      selectedProduct: (state) => state.products.selectedProduct,
      products: (state) => state.products.list,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    selectedProductId: {
      get() {
        return this.selectedProduct?.id;
      },
      set(newValue, oldValue) {
        this.SET_SELECTED_PRODUCT(
          this.products.find((item) => item.id === newValue)
        );
        if (newValue !== oldValue) {
          this.resetSignatures();
          this.infiniteId += 1;
        }
      },
    },
    productOptions() {
      return this.products.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    },
    menuOptions() {
      return [
        this.checkPermission("waivers.edit") && {
          value: "edit",
          name: "Edit",
        },
        this.checkPermission("waivers.delete") && {
          value: "delete",
          name: "Delete",
        },
      ].filter(Boolean);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchProducts();
      await this.fetchWaivers();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchWaivers: "waivers/fetchWaivers",
      deleteWaiver: "waivers/deleteWaiver",
      selectActiveWaiver: "products/selectActiveWaiver",
      resetSignatures: "signatures/resetSignatures",
    }),
    ...mapMutations({
      SET_SELECTED_PRODUCT: "products/SET_SELECTED_PRODUCT",
    }),
    async handleContextMenu(option, waiver) {
      if (option.value === "edit") {
        await this.$router.push({
          name: "EditWaiver",
          params: {
            id: waiver.id,
          },
        });
      } else if (option.value === "delete") {
        const confirmed = await dialog.confirm({
          title: "Delete Waiver?",
          message: "All waiver data will be lost",
          okText: "Delete",
          cancelText: "Cancel",
        });
        if (confirmed) {
          try {
            this.isLoading = true;
            await this.deleteWaiver(waiver.id);
            await this.fetchWaivers();
          } finally {
            this.isLoading = false;
          }
        }
      }
    },
    goToCreation() {
      this.$router.push({
        name: "CreateWaiver",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.waivers-page {
  display: flex;
  flex-direction: column;

  &__wrapper {
    @media (min-width: $media-laptop) {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 20px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;

    @media (min-width: $media-tablet) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px 20px;
    }

    @media (min-width: $media-desktop) {
      grid-template-columns: repeat(4, 1fr);
      gap: 24px 20px;
    }
  }
}
</style>
