<template>
  <div class="signatures-section">
    <div class="signatures-section__header">
      <Title>Signatures</Title>
      <Button
        variant="secondary"
        is-small
        is-outlined
        :is-loading="isExporting"
        :isDisabled="!signatures || !signatures.length"
        @click="handleSignaturesExport"
      >
        <Icon name="download" color="currentColor" />
        Export to CSV
      </Button>
    </div>
    <div class="signatures-section__filters">
      <div class="signatures-section__filters-top-wrapper">
        <div class="signatures-section__filters-left">
          <div class="signatures-section__filterTypes">
            <button
              v-for="button in typeFilters"
              :key="button.value"
              :class="[
                'signatures-section__filterType',
                {
                  'm-active': signatureType === button.value,
                },
              ]"
              @click="handleSelectTtypeFilter(button.value)"
            >
              {{ button.name }}
            </button>
            <div
              class="signatures-section__sort"
              @click="isFiltersShow = !isFiltersShow"
            >
              <div
                :class="[
                  'signatures-section__sort__icon',
                  { 'm-count': hiddenFiltersCount },
                ]"
                :data-count="hiddenFiltersCount"
              >
                <Icon
                  :size="24"
                  name="filters"
                  :color="isFiltersShow ? 'primary' : 'black'"
                />
              </div>
              <p class="signatures-section__sort__text">
                {{ `${isFiltersShow ? "Minimize" : "More"} filters` }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="isSortEnabled">
          <Dropdown
            :value="activeSort"
            :options="sortOptions"
            :is-disabled="isExporting"
            @select="handleSortChange"
          />
        </div>
      </div>
      <div v-if="isFiltersShow" class="signatures-section__filtersWrapper">
        <Select
          :options="waivers"
          label="Waiver"
          placeholder="Select waiver"
          v-model="selectedWaiverIdFilter"
        />
        <div @click="handleOpenDateModal">
          <Input
            v-model="dateRangeFilterString"
            label="Set period"
            placeholder="Select date"
            appendIcon="calendar"
            :isReadonly="true"
          />
        </div>

        <button class="signatures-section__reset" @click="handleResetFilters">
          <Icon
            class="signatures-section__reset__icon"
            :size="24"
            name="reload"
            color="secondary-500"
          />
          <p class="signatures-section__reset__text">Reset Filters</p>
        </button>
      </div>
      <WaiverSearch
        v-model="searchQuery"
        :field.sync="searchField"
        :is-disabled="isExporting"
        @search="handleSearch"
      />
      <DatePeriodModal v-model="dateRange" />
    </div>

    <Loader
      v-if="isLoading || isExporting"
      color="primary"
      size="m"
      class="mx-auto"
    />
    <template v-else>
      <SignaturesList
        class="grid-2"
        :signatures="signatures"
        @click="goToSignature"
      />
      <infinite-loading
        class="grid-2__full-item"
        :identifier="innerInfiniteId"
        @infinite="infiniteHandler"
      >
        <Loader slot="spinner" class="mx-auto" color="primary" size="m" />
        <div class="empty" slot="no-results">There are no signatures yet</div>
        <div slot="no-more"></div>
      </infinite-loading>
    </template>
  </div>
</template>

<script>
import SignaturesList from "@/components/signatures/SignaturesList";
import Dropdown from "@/components/common/Dropdown";
import { mapActions, mapMutations, mapState } from "vuex";
import { SignaturesSortEnum } from "@/helpers/enums";
import WaiverSearch from "@/components/waivers/WaiverSearch";
import dialog from "@/plugins/dialog";
import { SignatureTypeEnum } from "@/helpers/enums";
import DatePeriodModal from "@/components/bookings/DatePeriodModal";
import { dateRangeOptions } from "@/helpers/mocks";
import moment from "moment";

export default {
  name: "SignaturesSection",
  components: { Dropdown, SignaturesList, WaiverSearch, DatePeriodModal },
  props: {
    infiniteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rangeButtons: [...dateRangeOptions],
      isLoading: false,
      isExporting: false,
      isFiltersShow: false,
      limit: 15,
      typeFilters: [
        {
          name: "All",
          value: "",
        },
        {
          name: "Bookings",
          value: SignatureTypeEnum.BOOKINGS,
        },
        {
          name: "Walk-ins",
          value: SignatureTypeEnum.WALKINS,
        },
      ],
      sortOptions: [
        {
          name: "New first",
          value: SignaturesSortEnum.NEW,
        },
        {
          name: "Old first",
          value: SignaturesSortEnum.OLD,
        },
        {
          name: "A - Z",
          value: SignaturesSortEnum.AZ,
        },
        {
          name: "Z - A",
          value: SignaturesSortEnum.ZA,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      waivers: (state) =>
        state.waivers?.list?.map((item) => ({
          name: item.title,
          value: item.id,
        })),
      signatures: (state) => state.signatures.list,
      activeSort: (state) => state.signatures.activeSort,
      signatureType: (state) => state.signatures.signatureType,
      waiverIdFilter: (state) => state.signatures.waiverIdFilter,
      periodFilter: (state) => state.signatures.periodFilter,
      isSortEnabled: (state) => state?.signatures?.isSortEnabled,
    }),
    innerInfiniteId: {
      get() {
        return this.infiniteId;
      },
      set(newValue) {
        this.$emit("update:infiniteId", newValue);
      },
    },
    hiddenFiltersCount() {
      return [this.periodFilter.length, this.waiverIdFilter].filter(Boolean)
        .length;
    },
    selectedWaiverIdFilter: {
      get() {
        return this.waiverIdFilter;
      },
      set(newValue) {
        const oldValue = this.selectedWaiverIdFilter;
        this.resetSignatures();

        if (newValue !== oldValue) {
          this.SET_WAIVER_ID_FILTER(newValue);
        } else {
          this.SET_WAIVER_ID_FILTER(null);
        }
        this.innerInfiniteId += 1;
      },
    },
    searchQuery: {
      get() {
        return this.$store.state.signatures.searchQuery;
      },
      set(newValue) {
        this.$store.commit("signatures/SET_SEARCH_QUERY", newValue);
      },
    },
    searchField: {
      get() {
        return this.$store.state.signatures.searchField;
      },
      set(newValue) {
        this.$store.commit("signatures/SET_SEARCH_FIELD", newValue);
        this.searchQuery = "";
      },
    },
    dateRange: {
      get() {
        return this.periodFilter;
      },
      set(value) {
        this.resetSignatures();
        this.innerInfiniteId += 1;
        this.SET_PERIOD_FILTER(value);
      },
    },
    dateRangeFilterString() {
      if (!this.dateRange?.length) return "";
      const [from, to] = this.dateRange;
      const formattedFrom = moment(from).format("MMM DD, YYYY");
      if (from === to || !to) {
        return formattedFrom;
      } else {
        const formattedTo = moment(to).format("MMM DD, YYYY");
        return `${formattedFrom} - ${formattedTo}`;
      }
    },
  },
  created() {
    try {
      this.isLoading = true;
      this.resetSignatures();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchSignatures: "signatures/fetchSignatures",
      getAllSignaturesWithFilters: "signatures/getAllSignaturesWithFilters",
      resetSignatures: "signatures/resetSignatures",
      exportSignaturesToCSV: "signatures/exportSignaturesToCSV",
    }),
    ...mapMutations({
      SET_ACTIVE_SORT: "signatures/SET_ACTIVE_SORT",
      SET_SIGNATURE_TYPE: "signatures/SET_SIGNATURE_TYPE",
      SET_PERIOD_FILTER: "signatures/SET_PERIOD_FILTER",
      SET_WAIVER_ID_FILTER: "signatures/SET_WAIVER_ID_FILTER",
    }),
    goToSignature(signature) {
      this.$router.push({
        name: "SignatureDetails",
        params: {
          id: signature.id,
        },
      });
    },
    handleSelectTtypeFilter(type) {
      this.resetSignatures();
      this.innerInfiniteId += 1;
      this.SET_SIGNATURE_TYPE(type);
    },
    handleResetFilters() {
      this.SET_SIGNATURE_TYPE("");
      this.SET_PERIOD_FILTER([]);
      this.SET_WAIVER_ID_FILTER(null);
      this.resetSignatures();
      this.innerInfiniteId += 1;
    },
    handleOpenDateModal() {
      this.$modal.show("datePeriodModal");
    },
    async infiniteHandler($state) {
      try {
        const len = await this.fetchSignatures(this.limit);
        if (len) {
          $state.loaded();
          if (len < this.limit) {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.complete();
      }
    },
    handleSortChange(option) {
      if (this.activeSort !== option.value) {
        this.resetSignatures();
        this.SET_ACTIVE_SORT(option.value);
        this.innerInfiniteId += 1;
      }
    },
    handleSearch() {
      this.resetSignatures();
      this.innerInfiniteId += 1;
    },
    async handleSignaturesExport() {
      try {
        const isConfirmed = await dialog.confirm({
          title: "Are you sure?",
          message:
            "This action will export ALL signatures. \n This might take some time.",
          okText: "Yes, continue",
          cancelText: "Cancel",
        });
        if (!isConfirmed) {
          return;
        }
        try {
          this.isExporting = true;
          const signatures = await this.getAllSignaturesWithFilters();
          this.exportSignaturesToCSV(signatures);
        } catch (err) {
          console.error(err);
        } finally {
          this.isExporting = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signatures-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__wrapper {
    @media (min-width: $media-laptop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px 20px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    white-space: nowrap;
  }

  &__filters {
    display: flex;
    flex-direction: column;

    &-top-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 16px;

      @media (min-width: $media-tablet) {
        margin-bottom: 24px;
      }
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__filtersWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 20px;
    align-items: end;

    @media (max-width: $media-laptop - 1) {
      grid-template-columns: 1fr;
    }
  }

  &__sort {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 24px;

    &__text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #384966;
    }

    &__icon {
      user-select: none;
      position: relative;
      height: 24px;
      &.m-count {
        &::before {
          content: attr(data-count);
          position: absolute;
          top: -4ox;
          right: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;

          background-color: $primary;
          border-radius: 50%;

          color: $white;
          font-size: 9px;
          font-weight: 700;
        }
      }
    }
  }
  &__reset {
    cursor: pointer;
    height: 67px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 12px;

    &__text {
      color: $secondary-500;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__icon {
      transform: scaleX(-1) rotate(275deg);
    }
  }

  &__titleCount {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: var(--color-primary);
  }

  &__filterTypes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    // margin-bottom: 16px;

    // @media (min-width: $media-tablet) {
    //   margin-bottom: 24px;
    // }
  }

  &__add-btn {
    margin-bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__filterType {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 3px 16px;
    background: transparent;
    border: 2px solid #384966;
    color: #384966;
    border-radius: 6px;
    transition: 0.3s;
    cursor: pointer;
    opacity: 0.4;

    &.m-active {
      opacity: 1;
    }
  }

  &__reset {
    cursor: pointer;
    height: 67px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 12px;

    &__text {
      color: $secondary-500;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__icon {
      transform: scaleX(-1) rotate(275deg);
    }
  }
}
</style>
