<template>
  <div class="waiver-card" :class="{ active: isActive }">
    <div class="header">
      <Guard permission="products.edit" v-slot="{ isAvailable }">
        <Switcher
          :value="isActive"
          is-transparent
          :is-disabled="!isAvailable"
          @input="$emit('select')"
        />
      </Guard>

      <ContextMenu
        v-if="menuOptions.length"
        :options="menuOptions"
        @select="$emit('context-menu', $event)"
      >
        <Icon name="dotted" isClickable color="secondary-100" />
      </ContextMenu>
    </div>
    <span class="name">
      {{ title }}
    </span>
  </div>
</template>

<script>
import Guard from "@/components/common/Guard";
export default {
  name: "WaiverCard",
  components: { Guard },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.waiver-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $secondary-400;
  border-radius: 8px;
  color: $white;
  height: 90px;
  position: relative;
  transition: 0.3s;

  &.active {
    background-color: $primary;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }

  .name {
    padding: 18px 15px;
    font-size: 14px;
    line-height: 12px;
  }
}
</style>
